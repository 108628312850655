import {
  Component,
  ElementRef,
  HostListener,
  forwardRef,
  AfterViewInit,
  SecurityContext,
} from '@angular/core';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { decode } from 'html-entities';

@Component({
  standalone: true,
  selector: '[text-editable]',
  template: '<ng-content></ng-content>',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextEditableDirective),
      multi: true,
    },
  ],
  styles: [
    `@import 'scss/colors';
    :host {
      word-break: break-word;
      outline: none;
    }
    :host[disabled='true'] {
      pointer-events: none;
      background: #f9f9f9;
    }
    :host:empty::before {
      content: attr(placeholder);
    }
    :host:empty {
      &:hover,
      &:focus {
        &::before {
          color: $eventThemeActionColor;
        }
      }
    }
    `,
  ],
})
export class TextEditableDirective
  implements ControlValueAccessor, AfterViewInit
{
  @HostListener('blur', ['$event']) callOnTouched(event: Event) {
    if ((event.target as HTMLElement).closest('.ql-container')) {
      return;
    }

    const textContent = this.el.nativeElement.innerText.trim();
    if (textContent.length <= 1000) {
      this.onChange &&
      this.onChange(
        this.convertHtmlToString(this.el.nativeElement.innerHTML),
      );
      this.onTouched && this.onTouched();
    }
  }

  @HostListener('document:keydown.enter', ['$event'])
  onDocumentKeydownEnter(event: KeyboardEvent) {
    if ((event.target as HTMLElement).closest('.ql-container')) {
      return;
    }

    event.preventDefault();
    (event.target as HTMLElement).blur();
  }

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if ((event.target as HTMLElement).closest('.ql-container')) {
      return;
    }

    const maxLenAttr = (event.target as HTMLElement).getAttribute('maxlength');
    const maxLen = maxLenAttr ? parseInt(maxLenAttr, 10) : null;    if (maxLen) {
      const len = (event.target as HTMLElement).innerText.trim().length;
      let hasSelection = false;
      const selection = window.getSelection();
      const isSpecial = this.utils.isSpecial(event);
      const isNavigational = this.utils.isNavigational(event);

      if (selection) {
        hasSelection = !!selection.toString();
      }

      if (isSpecial || isNavigational) {
        return true;
      }

      if (len >= maxLen && !hasSelection) {
        event.preventDefault();
        return false;
      }

      return true;
    }

    return true;
  }

  onChange?: (value: string) => void;
  onTouched?: () => void;

  constructor(
    private el: ElementRef,
    private sanitizer: DomSanitizer,
  ) {}

  ngAfterViewInit() {
    this.el.nativeElement.setAttribute('contenteditable', 'true');
  }

  writeValue(value: any) {
    this.el.nativeElement.innerText = value || '';
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(val: boolean): void {
    this.el.nativeElement.setAttribute('disabled', String(val));
    this.el.nativeElement.setAttribute('contenteditable', String(!val));
  }

  keys = {
    backspace: 8,
    shift: 16,
    ctrl: 17,
    alt: 18,
    delete: 46,
    leftArrow: 37,
    upArrow: 38,
    rightArrow: 39,
    downArrow: 40,
  };

  utils = {
    special: {
      [this.keys['backspace']]: true,
      [this.keys['shift']]: true,
      [this.keys['ctrl']]: true,
      [this.keys['alt']]: true,
      [this.keys['delete']]: true,
    },
    navigational: {
      [this.keys['upArrow']]: true,
      [this.keys['downArrow']]: true,
      [this.keys['leftArrow']]: true,
      [this.keys['rightArrow']]: true,
    },
    isSpecial(e: any) {
      return typeof this.special[e.keyCode] !== 'undefined';
    },
    isNavigational(e: any) {
      return typeof this.navigational[e.keyCode] !== 'undefined';
    },
  };

  private convertHtmlToString(html: string): string {
    const safeHtml: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(html);
    let stringHtml = this.sanitizer.sanitize(SecurityContext.HTML, safeHtml);

    if (!stringHtml) {
      return html;
    }

    stringHtml = stringHtml.replace(/<br\s*(?:\/|[^>]*)>/gi, '\n');
    stringHtml = stringHtml.replace(/<[^>]+>/g, '');
    return decode(stringHtml);
  }
}
